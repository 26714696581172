import Swal from 'sweetalert2';
import {
    formatError,
    login,
    runLogoutTimer,
    saveTokenInLocalStorage,
    signUp,
} from '../../services/AuthService';
import { UseAPI } from '../../services/UseAPI';


export const SIGNUP_CONFIRMED_ACTION = '[signup action] confirmed signup';
export const SIGNUP_FAILED_ACTION = '[signup action] failed signup';
export const LOGIN_CONFIRMED_ACTION = '[login action] confirmed login';
export const LOGIN_FAILED_ACTION = '[login action] failed login';
export const LOADING_TOGGLE_ACTION = '[Loading action] toggle loading';
export const LOGOUT_ACTION = '[Logout action] logout action';

export function signupActionPasscode(inputs, history) {    
    return (dispatch) => {
        console.log("inputs",inputs);
		let create_pass_code = UseAPI('pass_code_general/registration',inputs,'POST').then((resp)=>{
			console.log(resp);
			if (resp.status == 'ok') {
				Swal.fire({
					title: 'Kode Konfirmasi',
					html: resp.pesan,
					input: 'text',
					inputAttributes: {
					  autocapitalize: 'off'
					},
					showCancelButton: true,
					confirmButtonText: 'Konfirmasi',
					showLoaderOnConfirm: true,
					preConfirm: (confirmPassCode) => {
                        return UseAPI('pass_code_general/confirm',{token:resp.data,passcode:confirmPassCode,waktu:resp.waktu},'POST').then((resp_pass_code)=>{
                            // return resp_pass_code
                            // console.log("resp_pass_code",resp_pass_code);
                            if (resp_pass_code.status == 'ok') {
                                return signUp(inputs)
                                .then((response_user) => {
                                    console.log('response_user',response_user);
                                    if (response_user.status === 'er') {
                                        throw new Error(response_user.msg)

                                        // const errorMessage = formatError(response_user);
                                        // dispatch(signupFailedAction(errorMessage));    
                                    } else {
                                        return response_user;            
                                    }
                                })
                                .catch((error) => {
                                    Swal.fire({
                                        icon:'error',
                                        title: `Error`,
                                        text: `${error}`
                                    })
        
                                    // console.log(error);
                                    // const errorMessage = formatError(error.response.data);
                                    // dispatch(signupFailedAction(errorMessage));
                                });                                   

                            } else {
                            	throw new Error(resp_pass_code.pesan)
                            }
                        }).catch(error => {
                            Swal.fire({
                                icon:'error',
                                title: `Error`,
                                text: `${error}`
                            })

                        })
					},
					allowOutsideClick: () => !Swal.isLoading()
				}).then((finalresult) => {
                    console.log("final result",finalresult);    
                    if (finalresult.value) {
                        let timerInterval;
                        Swal.fire({
                            icon:'success',
                            title: `Mempersiapkan halaman dashboard`,
                            html:'<b></b>',
                            // text: `${result.value.pesan}`,
                            showConfirmButton:false,
                            timer:3000,
                            timerProgressBar: true,
                            didOpen: () => {
                                Swal.showLoading()
                                const b = Swal.getHtmlContainer().querySelector('b')
                                timerInterval = setInterval(() => {
                                    b.textContent = parseInt((Swal.getTimerLeft()/1000)+1)
                                }, 100)
                            },
                                willClose: () => {
                                clearInterval(timerInterval)
                            }							
                        }).then((result)=>{
                            if (result.dismiss === Swal.DismissReason.timer) {        
                                saveTokenInLocalStorage(finalresult.value.data);
                                runLogoutTimer(
                                    dispatch,
                                    finalresult.value.data.expiresIn * 1000,
                                    history,
                                );
                                dispatch(confirmedSignupAction(finalresult.value.data));
                                history.push('/dashboard');
                            }
                        })                          
                    }
                    
				})
			} else {
				Swal.fire({
					icon:'error',
					title: `Error`,
					text: `${resp.pesan}. `
				})
			}

	

		})		


    };
}
export function signupAction(inputs, history) {
    return (dispatch) => {
        return signUp(inputs)
        .then((response_user) => {
            // console.log(response_user);
            
            // console.log('response_user',response_user);
            if (response_user.status === 'er') {
                throw new Error(response_user.msg)

                // const errorMessage = formatError(response_user);
                // dispatch(signupFailedAction(errorMessage));    
            } else {
                let timerInterval;
                Swal.fire({
                    icon:'success',
                    // text: `${response_user.msg}`,
                    html: `${response_user.msg}<br />Kembali ke halaman login dalam <b></b> detik`,
                    showConfirmButton:false,
                    timer:5000,
                    timerProgressBar: true,
                    didOpen: () => {
                        Swal.showLoading()
                        const b = Swal.getHtmlContainer().querySelector('b')
                        timerInterval = setInterval(() => {
                            b.textContent = parseInt((Swal.getTimerLeft()/1000)+1)
                        }, 100)
                    },
                        willClose: () => {
                            clearInterval(timerInterval);
                            history.push('/login');                

                    }
                })
                    // saveTokenInLocalStorage(response_user.data);
                    // runLogoutTimer(
                    //     dispatch,
                    //     response_user.data.expiresIn * 1000,
                    //     history,
                    // );
                // return response_user;            
            }
        })
        .catch((error) => {
            console.log(error);
            
            Swal.fire({
                icon:'error',
                title: `Error`,
                text: `${error}`
            })

            // console.log(error);
            // const errorMessage = formatError(error.response.data);
            // dispatch(signupFailedAction(errorMessage));
        });          
        // signUp(inputs)
        // .then((response) => {
        //     saveTokenInLocalStorage(response.data);
        //     runLogoutTimer(
        //         dispatch,
        //         response.data.expiresIn * 1000,
        //         history,
        //     );
        //     dispatch(confirmedSignupAction(response.data));
        //     history.push('/dashboard');
        // })
        // .catch((error) => {
        //     const errorMessage = formatError(error.response.data);
        //     dispatch(signupFailedAction(errorMessage));
        // });
    };
}

export function logout(history) {
    localStorage.removeItem('userDetails');
    history.push('/login');
    return {
        type: LOGOUT_ACTION,
    }; 
}

export function loginAction(email, password, history) {
    return (dispatch) => { 
        login(email, password)
            .then((response) => {
                console.log("response",response);
                if (response.status === 'er') {
                    var errorbaru = {
                        error: {
                            message:'PASSWORD SALAH',
                            data: response.msg
                        }
                    }
                    const errorMessage = formatError(errorbaru);
                    dispatch(loginFailedAction(errorMessage));    
                } else {
                    saveTokenInLocalStorage(response.data);
                    runLogoutTimer(
                        dispatch,
                        response.data.expiresIn * 1000,
                        history,
                    );
                    dispatch(loginConfirmedAction(response.data));
                    history.push('/dashboard');                
                }
            })
            .catch((error) => {
				console.log(error);
                const errorMessage = formatError(error);
                dispatch(loginFailedAction(errorMessage));
            });
    };
}
export function loginFailedAction(data) {
    return {
        type: LOGIN_FAILED_ACTION,
        payload: data,
    };
}

export function loginConfirmedAction(data) {
    return {
        type: LOGIN_CONFIRMED_ACTION,
        payload: data,
    };
}

export function confirmedSignupAction(payload) {
    return {
        type: SIGNUP_CONFIRMED_ACTION,
        payload,
    };
}

export function signupFailedAction(message) {
    return {
        type: SIGNUP_FAILED_ACTION,
        payload: message,
    };
}

export function loadingToggleAction(status) {
    return {
        type: LOADING_TOGGLE_ACTION,
        payload: status,
    };
}
