import React, { useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import { QrReader } from 'react-qr-reader';
import { UseAPI } from '../../../services/UseAPI';
import Swal from 'sweetalert2';

const Presensi = () => {
  const [result, setResult] = useState('No result');
  const [scanning, setScanning] = useState(false);
  const [cameraFacingMode, setCameraFacingMode] = useState('environment'); // Default to rear camera
  const { id } = useParams();
  const history = useHistory();
//   const navigate = 

  useEffect(async () => {
    try {
        const response = await UseAPI(`kegiatan/${id}/is_active`, {}, 'GET');
        console.log(response);
        
        // Check response status
        // if (response.status === 'success') {
        //   Swal.fire({
        //     title: 'Berhasil',
        //     text: response.pesan,
        //     icon: 'success',
        //   });
        // } else {
        //   Swal.fire({
        //     title: 'Gagal',
        //     text: response.pesan,
        //     icon: 'error',
        //   });
        // }
      } catch (error) {
        Swal.fire({
          title: 'Error',
          text: 'Terjadi kesalahan dalam mengirim data / kegiatan belum aktif.',
          icon: 'error',
        }).then(() => {
          history.push('/');
        });
      }
  }, []);
  
  const handleScan = (data) => {
    if (data) {
      setResult(data?.text || 'No result');
      Swal.fire({
        title: 'Sedang memproses...',
        allowOutsideClick: false,
        onBeforeOpen: () => {
          Swal.showLoading();
        }
      });

      const sendRequest = async () => {
        try {
          const response = await UseAPI(`kegiatan/${id}/scan`, { nim: data?.text }, 'POST');
          // Check response status
          if (response.status === 'success') {
            Swal.fire({
              title: 'Berhasil',
              text: response.pesan,
              icon: 'success',
            });
          } else {
            Swal.fire({
              title: 'Gagal',
              text: response.pesan,
              icon: 'error',
            });
          }
        } catch (error) {
          Swal.fire({
            title: 'Error',
            text: 'Terjadi kesalahan dalam mengirim data.',
            icon: 'error',
          });
        }
        setScanning(false);
      };
      sendRequest();
    }
  };

  const handleError = (err) => {
    console.error(err);
    Swal.fire({
      title: 'Error',
      text: 'Gagal membuka kamera. Silakan periksa izin kamera.',
      icon: 'error',
    });
  };

  const startScanning = () => {
    setScanning(true);
    clearResult(); // Clear result when starting new scan
  };

  const stopScanning = () => {
    setScanning(false);
  };

  const clearResult = () => {
    setResult('No result');
  };

  const switchCamera = () => {
    setCameraFacingMode(cameraFacingMode === 'environment' ? 'user' : 'environment');
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-12 text-center">
          {scanning ? (
            <QrReader
              delay={300}
              onError={handleError}
              onResult={handleScan}
              constraints={{
                facingMode: cameraFacingMode // Use camera constraints instead of the `camera` prop
              }}
              style={{ width: '100%' }}
            />
          ) : (
            <p>{result}</p>
          )}
        </div>
      </div>

      <div className="row">
        <div className="col-md-12 text-center">
          <button onClick={startScanning} className="btn btn-primary m-3" disabled={scanning} style={{width: '100%'}}>
            Scan QR
          </button>
          {/* <button onClick={stopScanning} className="btn btn-danger" disabled={!scanning}>
            Stop Scanning
          </button>
          <button onClick={switchCamera} className="btn btn-info" disabled={!scanning}>
            Switch Camera
          </button> */}
        </div>
      </div>
    </div>
  );
};

export default Presensi;
