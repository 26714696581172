import React, { Fragment, useState, useRef } from "react";
import { UseAPI } from "../../../services/UseAPI";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faClock } from "@fortawesome/free-solid-svg-icons";
import { useEffect } from "react";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button } from "react-bootstrap";
import Swal from "sweetalert2";
import { useHistory } from "react-router-dom";
import JoditEditor from 'jodit-react';


const TryoutStart = () => {
  const { tryout_id } = useParams();
  const [data, setData] = useState([]);
  const [soalAll, setSoalAll] = useState([]);
  const history = useHistory();
  const [selectedRadio, setSelectedRadio] = useState('');
  const [timer, setTimer] = useState(0);

  const editor = useRef(null);

  const config = {
    readonly: true,
    placeholder: '' || '',
    toolbar: false,
    statusbar: false,
    minHeight: '30px',
  };

  const handleSave = async () => {
    try {
      const result = await Swal.fire({
        title: 'Are you sure?',
        text: "You will save all your answers and exit the test. They cannot be changed afterwards.",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: 'Cancel',
      });

      if (result.isConfirmed) {
        Swal.fire({
          title: 'Saving...',
          text: 'Please wait until the process is complete',
          allowOutsideClick: false,
          didOpen: () => {
            Swal.showLoading();
          }
        });
        const postdata = [];
        soalAll.map((item) => {
          return (
            postdata.push({
              id: item.id,
              data: {
                tryout_question_id: item.tryout_question_id,
                tryout_id: item.tryout_id,
                question_id: item.question_id,
                answer: item.answer
              }
            })
          )
        })

        try {
          const resp = await UseAPI('tryout_questions/simpan_jawaban', {
            data: JSON.stringify(postdata)
          }, 'POST');

          //console.log(resp);
          Swal.close();
          Swal.fire({
            title: resp.status,
            text: resp.message,
            icon: resp.status,
          }).then((result) => {
            if (result.isConfirmed) {
              history.push(`/tryout`);
            }
          });
        } catch (error) {
          Swal.close();
          Swal.fire({
            title: 'Error!',
            text: 'There was an error saving your answer. Please try again.',
            icon: 'error',
          });
        }
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  }

  const handleRadio = async (event) => {
    // Swal.showLoading();
    try {
      await UseAPI(`tryout_questions/simpan_jawaban/${data.id}`, {
        answer: event.target.value
      }, 'POST');
      Swal.close();
    } catch (error) {
      Swal.fire({
        title: 'Error!',
        text: 'There was an error saving your answer. Please try again.',
        icon: 'error',
      });
    }

    
    //console.log("handleRadio", event.target.value);
    //console.log("soalAll initial", soalAll);
    const newData = [...soalAll];
    const optionCurrent = newData.find(a => a.id === data.id);
    optionCurrent.answer = event.target.value;
    setSoalAll(newData)
    setTimeout(() => {
      //console.log("soalAll", soalAll);
    }, 500);
    setSelectedRadio(event.target.value);
  }

  const handleVisibilityChange = async () => {
    if (document.visibilityState === 'hidden') {
      try {
        await UseAPI('watching', {
          tryout_id: JSON.parse(localStorage['soal']).tryout_id,
          question_id: JSON.parse(localStorage['soal']).question_id,
          watched: 'leaved'
        }, 'POST');
      } catch (error) {
        console.error('An error occurred:', error);
      }
    }
  };

  const handleBeforeUnload = async (event) => {
    event.preventDefault();
    event.returnValue = '';
    try {
      await UseAPI('watching', {
        tryout_id: JSON.parse(localStorage['soal']).tryout_id,
        question_id: JSON.parse(localStorage['soal']).question_id,
        watched: 'refreshed'
      }, 'POST');
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  useEffect(() => {
    document.addEventListener('visibilitychange', handleVisibilityChange);
    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange);
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const postPeriodicData = async () => {
    try {
      const response = await UseAPI(`tryout_questions/is_active/${tryout_id}`, {}, 'GET');
      return response;
    } catch (error) {
      console.error('An error occurred while posting periodic data:', error);
      return null;
    }
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      const response = await postPeriodicData();
      if (response && response.status === 'error') {
        Swal.fire({
          icon: response.status,
          title: response.status,
          text: response.pesan,
          allowOutsideClick: true,
          allowEscapeKey: true,
        }).then((result) => {
          history.push('/');
        });
      } else {
        console.log('response', response);
        
        setTimer(response?.data?.data?.time_remaining_minutes);
      }
    }, 5000); // Post data every 30 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [history, tryout_id]);

  useEffect(() => {
    UseAPI('tryout_questions/getsoal', { tryout_id: tryout_id }, 'POST').then((resp) => {
      //console.log("initial response useEffect", resp);
      if (resp.status === 'ok') {
        setData(resp.data.soal);
        setSoalAll(resp.data.soal_all);
        setSelectedRadio(resp.data.soal.answer);
      } else {
        let timerInterval;
        Swal.fire({
          icon: 'error',
          title: `Failed`,
          html: `${resp.message} (<b></b>)`,
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
          didOpen: () => {
            Swal.showLoading()
            const b = Swal.getHtmlContainer().querySelector('b')
            timerInterval = setInterval(() => {
              b.textContent = parseInt((Swal.getTimerLeft() / 1000) + 1)
            }, 100)
          },
          willClose: () => {
            clearInterval(timerInterval)
          }
        }).then((result) => {
          if (result.dismiss === Swal.DismissReason.timer || result.dismiss === Swal.DismissReason.backdrop) {
            history.push(`/tryout/${tryout_id}`)
          }
        })
      }

    });
  }, [history, tryout_id])

  const gotoSoal = async (item) => {
    try {
      localStorage['soal'] = JSON.stringify(item);
      await setData(item);
      await setSelectedRadio(item.answer);
    } catch (error) {
      console.error("Error setting data or selected radio:", error);
    }
  };


  return (
    <Fragment>
      <div className="row">
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-9">
          <div className="card">
            <div className="card-header">
              <div>Please press the FINISH button to submit your answers</div>
              <span>
                <Button onClick={() => handleSave(data.no_urut, data.id, data.tryout_id)}>
                  <FontAwesomeIcon icon={faCheck} className="pe-2" />
                  FINISH
                </Button>
                <Button className="mx-2 fs-20" onClick={() => handleSave(data.no_urut, data.id, data.tryout_id)}>
                  <FontAwesomeIcon icon={faClock} className="pe-2 fs-20" />
                  {timer} minutes
                </Button>


              </span>
            </div>
            <div className="card-body p-4">
              <Button className="btn btn-primary btn-sm mb-3" style={{fontSize:"70px"}} >{data.no_urut}</Button>
              <div className="basic-form">                
                <form>
                  <JoditEditor
                    ref={editor}
                    value={data.question_text}
                    config={config}
                    tabIndex={1} // tabIndex of textarea
                  />
                  <fieldset className="form-group">
                    <div className="row mb-3 mt-3">
                      <div className="col-9">
                        {}
                        <div className="form-check">
                          <input
                            id="option_a"
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            value="option_a"
                            onChange={handleRadio}
                            checked={selectedRadio === "option_a"}

                          />
                          <label className="form-check-label">
                            <JoditEditor
                              ref={editor}
                              value={data.option_a}
                              config={config}
                              tabIndex={1} // tabIndex of textarea
                            />
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            id="option_b"
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            value="option_b"
                            onChange={handleRadio}
                            checked={selectedRadio === "option_b"}
                          />
                          <label className="form-check-label">
                            <JoditEditor
                              ref={editor}
                              value={data.option_b}
                              config={config}
                              tabIndex={1} // tabIndex of textarea
                            />
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            id="option_c"
                            className="form-check-input"
                            type="radio"
                            name="gridRadios"
                            value="option_c"
                            onChange={handleRadio}
                            checked={selectedRadio === "option_c"}
                          />
                          <label className="form-check-label">
                            <JoditEditor
                              ref={editor}
                              value={data.option_c}
                              config={config}
                              tabIndex={1} // tabIndex of textarea
                            />
                          </label>
                        </div>
                        {data.option_d && (
                          <div className="form-check">
                            <input
                              id="option_d"
                              className="form-check-input"
                              type="radio"
                              name="gridRadios"
                              value="option_d"
                              onChange={handleRadio}
                              checked={selectedRadio === "option_d"}

                            />
                            <label className="form-check-label">
                              <JoditEditor
                                ref={editor}
                                value={data.option_d}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                              />
                            </label>
                          </div>
                        )}
                        {data.option_d && (
                          <div className="form-check">
                            <input
                              id="option_e"
                              className="form-check-input"
                              type="radio"
                              name="gridRadios"
                              value="option_e"
                              onChange={handleRadio}
                              checked={selectedRadio === "option_e"}

                            />
                            <label className="form-check-label">
                              <JoditEditor
                                ref={editor}
                                value={data.option_e}
                                config={config}
                                tabIndex={1} // tabIndex of textarea
                              />
                            </label>
                          </div>

                        )}

                      </div>
                    </div>
                  </fieldset>


                </form>
              </div>
            </div>
            <div className="card-footer">
              My Answer: {data.answer && data.answer.split("_")[1].toUpperCase()}
            </div>
          </div>
        </div>
        <div className="col-sm-12 col-xs-12 col-md-12 col-lg-3">
          <div className="card">
            <div className="card-header">
              <h4 className="card-title">Question Lists</h4>
            </div>
            <div className="card-body p-3">
              {
                soalAll.map((soal, index) => (
                  <button key={index} onClick={() => gotoSoal(soal)} className={`${soal.answer ? 'd-flex justify-content-center align-items-center p-2 m-1 text-black bg-success' : 'd-flex justify-content-center align-items-center p-2 m-1 text-black bg-primary'}`} style={{ width: "40px", height: "40px", float: "inline-start" }}>{index + 1}</button>
                ))
              }
            </div>
          </div>
        </div>

      </div>

    </Fragment>
  );
};

export default TryoutStart;
